/**
 * @description Composable отключает scrollbar и при необходимости добавляет компенсатор.
 * @returns {{compensateScrollbar: () => void, deCompensateScrollbar: () => void}}
 */
export default function useDisableScrollbar() {
  function setHtmlOverflow() {
    document.documentElement.style.overflow = 'hidden';
  }
  function unsetHtmlOverflow() {
    document.documentElement.style.overflow = '';
  }

  function setBodyOverflow() {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'static';
  }
  function unsetBodyOverflow() {
    document.body.style.overflow = '';
    document.body.style.position = '';
  }

  function compensateScrollbar() {
    if (!window) return;

    const widthScrollbar = window.innerWidth - document.documentElement.clientWidth;
    setHtmlOverflow();
    setBodyOverflow();

    if (!widthScrollbar) return;
    document.body.style.paddingRight = `${widthScrollbar}px`;
  }

  function deCompensateScrollbar() {
    if (!window) return;

    unsetHtmlOverflow();
    unsetBodyOverflow();
    document.body.style.paddingRight = '';
  }

  return {
    compensateScrollbar,
    deCompensateScrollbar,
  };
}
